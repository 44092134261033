<template>
    <el-tabs style="height: 200px;">
        <el-tab-pane label="资源审核">
            <resources-examine></resources-examine>
        </el-tab-pane>
        <el-tab-pane label="资源录入">
            <resources-input></resources-input>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
    import ResourcesExamine from './resources-examine'
    import ResourcesInput from './resources-input'
    export default {
        name: "resources-manage",
        components: {
            ResourcesExamine,
            ResourcesInput
        }
    }
</script>

<style scoped>

</style>
